<template>
  <div v-nk-loading="loading">
    <div class="news-wrap page-width">
      <div class="left-search">
        <div class="select-wrap-title" style="width: 100%; padding-top: 20px;">
          <span class="iconfont fs-38 color-ori">&#xe7a8;</span>
          <span class="fs-26 font-w-bold">{{ $t('home.sellerSettledIn') }}</span>
        </div>
        <el-col class="col-wrap left-search-class web-show" :span="12" style="min-width: 200px;width:100%">
          <el-menu default-active="6" active-text-color="#4285F7" class="el-menu-vertical-demo">
            <template v-for="(item) in documentArr">
              <el-submenu v-if="item.children" :index="String(item.id)" :key="item.id">
                <template slot="title">
                  <span>{{ item.name }}</span>
                </template>
                <el-menu-item-group v-for="(ite) in item.children" :key="ite.id">
                  <el-menu-item :index="String(ite.id)" @click.native="currentIndex(ite)">{{ ite.name }}</el-menu-item>
                </el-menu-item-group>
              </el-submenu>
              <el-menu-item v-else :index="String(item.id)" :key="item.id">
                <span slot="title">{{ item.name }}</span>
              </el-menu-item>
            </template>
          </el-menu>
        </el-col>
      </div>
      <div class="compileCss" v-html="detailsData"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      input2: '',
      isDynamic: false,
      detailsData: '',
      documentArr: [],
      loading: false,
    }
  },
  watch: {
    $route() {
      this.userId = this.$route.query.id
    },
    userId() {
      console.log("重新请求" + this.userId + "的数据")
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.loading = true
      const data = {
        type: 1
      }
      this.$api.platformData(data).then(res => {
        this.loading = false
        this.documentArr = res.data
        for (let i = 0; i < this.documentArr.length; i++) {
          if (this.documentArr[i].children && this.documentArr[i].children.length > 0) {
            this.detailsData = this.documentArr[i].children[0].content
            break
          }
        }
      })
    },
    currentIndex(ite) {
      this.detailsData = ''
      this.detailsData = ite.content
    }
  }
}
</script>

<style scoped>
.bannerData .el-tabs__header {
  margin-bottom: 0 !important;
}
.col-wrap .el-menu {
  border: none !important;
}
/*@media screen and (max-width: 997px) {*/
/*  .page-width {*/
/*    width: 90%;*/
/*  }*/
/*  .news-wrap {*/
/*    flex-direction: column;*/
/*    padding: 50px 10px !important;*/
/*  }*/
/*  .news-wrap .compileCss {*/
/*    width: auto;*/
/*    margin-left: 0;*/
/*  }*/
/*  .left-search {*/
/*    padding-bottom: 20px;*/
/*    border-bottom: 1px solid rgba(136, 136, 136, 0.1);*/
/*  }*/
/*}*/
</style>
